<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <Steps
        :levels="levels"
        v-if="levels && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs
          v-model="active"
          :animated="true"
          :class="appStatus == 1 ? 'disappear' : ''"
        >
          <van-tab title="详情页" name="a">
            <van-collapse v-model="activeNames">
              <van-collapse-item title="委托信息" name="1" icon="label">
                <van-cell-group class="group">
                  <van-cell title="委托单名称" :value="details.authorizeName" />
                  <van-cell title="委托原因" :value="details.reason" />
                </van-cell-group>
              </van-collapse-item>
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                :immediate-check="false"
              >
                <van-collapse-item
                  title="采购申请信息"
                  name="2"
                  icon="label"
                  class="unique"
                >
                  <div
                    class="supplies"
                    v-for="(item, index) in this.projectlist"
                    :key="index"
                  >
                    <div class="supplies-title">{{ item.strprodname }}</div>
                    <div class="supplies-text">
                      物资编码:{{ item.prodcode }}
                    </div>
                    <div class="supplies-text">
                      采购申请编号: {{ item.ordercode }}
                    </div>
                    <div class="supplies-text">
                      数量: {{ item.number }}{{ item.measunit }}
                    </div>
                    <!-- <div class="supplies-text">
                      计划交货日期:{{ item.startdate }}
                    </div> -->
                    <div class="supplies-text">
                      项目编号: {{ item.projNum }}
                    </div>
                    <div class="supplies-text">
                      提交日期: {{ item.subtime }}
                    </div>
                  </div>
                </van-collapse-item>
              </van-list>
            </van-collapse>
          </van-tab>
          <van-tab title="审批记录" name="b" v-if="appStatus != 1">
            <div
              class="record"
              v-for="(item, index) in details.wfapproveinfos"
              :key="index"
            >
              <van-cell-group>
                <van-cell title="审批层级" :value="item.node"></van-cell>
                <van-cell title="审批节点" :value="item.nodename"></van-cell>
                <van-cell title="审批人" :value="item.userName"></van-cell>
                <van-cell title="审批时间" :value="item.enddatetime"></van-cell>
                <van-cell title="审批结论" :value="item.resultType"></van-cell>
                <van-cell
                  title="审批意见"
                  :value="item.result"
                  v-if="item.resultType == '不同意'"
                ></van-cell>
              </van-cell-group>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      pageIndex: 1,
      pageSize: 10,
      showage: true,
      title: "委托采购审批详情",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      levels: null,
      projectlist: [],
      projectId: "",
      hierarchy: "",
    };
  },
  created() {
    this.getProjectViewAu();
  },
  computed: {
    authorizeprojectId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    async getProjectViewAu() {
      let data = await this.$api.ec.getProjectViewAu(this.authorizeprojectId);
      if (data) {
        this.loadingfalg = false;
      }
      this.details = data;
      data.wfnodeVOs.map((item, index) => {
        if (item.isNodeaction == 1) {
          this.levels = index + 1;
          return;
        }
      });
      this.hierarchy = data.wfnodeVOs.length;
      this.projectId = data.id;
      this.Projectlist();
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          authorizeprojectId: this.authorizeprojectId,
          audit: 0,
          leadexam: message,
        };
      } else {
        params = {
          authorizeprojectId: this.authorizeprojectId,
          audit: 1,
          leadexam: "同意",
        };
      }
      let data = await this.$api.ec.auditAuthorizeproject(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecentrust",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    async Projectlist() {
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        AuthorizeprojectId: this.projectId,
      };
      let data = await this.$api.ec.listAp(params);
      if (data) {
        if (data.data) {
          if (data.data.length != 0) {
            this.projectlist = [...this.projectlist, ...data.data];
          }
          if (data.data.length != 10) {
            this.loading = true;
            this.finished = true;
          } else {
            this.loading = false;
            this.finished = false;
          }
        }
        if (!data.data) {
          this.loading = true;
          this.finished = true;
        }
      }
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "ecentrust",
        });
      } else {
        this.showage = true;
        this.title = "委托采购审批详情";
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onLoad() {
      this.pageIndex++;
      this.Projectlist();
    },
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.record {
  margin-top: 10px;
}
</style>
